<template>
  <div class="bg-light">
    <el-form
        :model="formData"
        status-icon
        :rules="rules"
        ref="form"
        label-width="auto"
        class="margin-top"
        style="width:800px;"
    >
	    <el-form-item label="AccessKeyId" prop="ali_sms_access_key_id">
		    <el-input v-model="formData.ali_sms_access_key_id" style="width:200px;"></el-input>
	    </el-form-item>
	    <el-form-item label="AccessKeySecret" prop="ali_sms_access_key_secret">
		    <el-input v-model="formData.ali_sms_access_key_secret" style="width:200px;"></el-input>
	    </el-form-item>
	    <el-form-item label="regionId" prop="ali_sms_region_id">
		    <el-input v-model="formData.ali_sms_region_id" style="width:200px;"></el-input>
	    </el-form-item>
      <el-form-item label="短信签名" prop="ali_sms_mark">
	      <el-input v-model="formData.ali_sms_mark" style="width:200px;"></el-input>
      </el-form-item>
      <el-form-item>
          <el-button type="primary" @click="submitForm('form')">确定</el-button>
          <el-button type="" @click="$router.back()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
    name: "sms",
    data() {
        return {
            formData: {
	            ali_sms_access_key_id: "",
	            ali_sms_access_key_secret:'',
	            ali_sms_region_id:'',
	            ali_sms_mark:'',
            },
            rules: {
	            ali_sms_access_key_id: [{required: true,message:'不能为空'}],
	            ali_sms_access_key_secret: [{required: true,message:'不能为空'}],
	            ali_sms_region_id: [{required: true,message:'不能为空'}],
	            ali_sms_mark: [{required: true,message:'不能为空'}],
            }
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        submitForm(formName) {
            let _this = this;
            console.log(_this.formData);
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.request({
                        url: "/SystemSetting/edit",
                        datas: {..._this.formData},
                        success: res => {
                            if (res === "ok") {
                                _this.$message.success("修改成功");
                            } else {
                                _this.$message.error("修改失败");
                            }
                            // _this.$message.info(res.msg);
                            // _this.$router.replace({path: '/cate/list'})
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        getDetail() {
            let _this = this;

            let _skeys = [];

            for (let k in this.formData) {
                _skeys.push(k);
            }

            _this.$http.request({
                url: "/SystemSetting/getSystem",
                datas: {skey: _skeys},
                success: res => {
                    _this.formData = res;
                }
            });
        }
    }
};
</script>
